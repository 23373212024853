import { useTheme } from "next-themes";
import { FC, useEffect } from "react";

import { Footer } from "../../components/organisms/Footer";
import { Header } from "../../components/organisms/Header";
import { SeoHead } from "../../components/atoms/SeoHead";

export type Props = {
  title: string;
  description: string;
  keywords?: string[];
  meta?: Array<{
    name: string;
    content: string;
  }>;
};

export const Layout: FC<Props> = ({
  children,
  title = "Krutik Parikh - Software Engineer",
  description,
  keywords,
  meta,
}) => {
  const { resolvedTheme } = useTheme();

  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']") as any;
    if (favicon) favicon.href = `/assets/favicon_${resolvedTheme}.svg`;
  }, [resolvedTheme]);

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <SeoHead
        title={title}
        description={description}
        meta={meta}
        keywords={keywords}
      />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};
