import Close from "../../../public/assets/close.svg";
import Download from "../../../public/assets/download.svg";
import Github from "../../../public/assets/github.svg";
import Instagram from "../../../public/assets/instagram.svg";
import Linkedin from "../../../public/assets/linkedin.svg";
import Menu from "../../../public/assets/menu.svg";
import Twitter from "../../../public/assets/twitter.svg";

export interface IconProps {
  icon:
    | "CLOSE"
    | "DOWNLOAD"
    | "GITHUB"
    | "INSTAGRAM"
    | "LINKEDIN"
    | "MENU"
    | "YOUTUBE"
    | "MAIL"
    | "FACEBOOK";
}

const Icon = ({ icon }: IconProps) => {
  let Icon = "";

  switch (icon) {
    case "CLOSE":
      Icon = "fas fa-times fa-2x";
      break;
    case "DOWNLOAD":
      Icon = "fas fa-download fa-2x";
      break;
    case "GITHUB":
      Icon = "fab fa-github fa-2x";
      break;
    case "INSTAGRAM":
      Icon = "fab fa-instagram fa-2x";
      break;
    case "LINKEDIN":
      Icon = "fab fa-linkedin-in fa-2x";
      break;
    case "MENU":
      Icon = "fas fa-bars fa-2x";
      break;
    case "YOUTUBE":
      Icon = "fab fa-youtube fa-2x";
      break;
    case "MAIL":
      Icon = "fas fa-envelope fa-2x";
      break;
    case "FACEBOOK":
      Icon = "fab fa-facebook fa-2x";
      break;
    default:
      break;
  }
  return (
    <>
      <i className={Icon}></i>
    </>
  );
};

export { Icon };
