import { SocialIcons } from "../../molecules/SocialIcons";

const Footer = () => {
  return (
    <footer className="flex flex-col md:flex-row gap-4 items-center justify-center md:justify-between mt-8 md:mt-20 px-4 md:px-20 py-8">
      <span>&copy; {new Date().getFullYear()} Krutik Parikh</span>
      <SocialIcons
        profiles={[
          {
            name: "Github",
            url: "https://github.com/parikrut",
            icon: "GITHUB",
          },
          {
            name: "LinkedIn",
            url: "https://www.linkedin.com/in/parikrut/",
            icon: "LINKEDIN",
          },
          {
            name: "Facebook",
            url: "https://www.facebook.com/people/Krutik-Parikh/100076183866649/",
            icon: "FACEBOOK",
          },
          {
            name: "Instagram",
            url: "https://www.instagram.com/ikrutikparikh/",
            icon: "INSTAGRAM",
          },
          {
            name: "Youtube",
            url: "https://www.youtube.com/channel/UCe0Aoc-4Eo_ywNYsPo5NEKg?view_as=subscriber",
            icon: "YOUTUBE",
          },
          {
            name: "Email",
            url: "mailto:4krutikparikh@gmail.com",
            icon: "MAIL",
          },
        ]}
      />
    </footer>
  );
};

export { Footer };
