import Head from "next/head";

export interface HeadProps {
	title: string;
	description: string;
	lang?: string;
	keywords?: string[];
	author?: string;
	image?: string;
	meta?: Array<{
		name: string;
		content: string;
	}>;
}

const SeoHead = ({
	title,
	description,
	author = "Krutik Parikh",
	keywords,
	image = "",
	meta,
}: HeadProps) => {
	return (
		<Head>
			<title>{title}</title>
			<link rel="manifest" href="/manifest.json" />
			<meta name="description" content={description} />
			<meta name="author" content={author} />
			<meta name="og:title" content={title} />
			<meta name="og:description" content={description} />
			<meta name="og:type" content="website" />
			<meta name="og:url" content="https://krutikparikh.ca/" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:creator" content={author} />
			<meta property="og:image" content={`/assets/profile.jpg`} />

			{meta &&
				meta.length > 0 &&
				meta.map(({ name, content }) => (
					<meta name={name} content={content} key={name} />
				))}
			{image && <meta name="og:image" content={image} />}
			{image && <meta name="twitter:image" content={image} />}
			{keywords && <meta name="keywords" content={keywords.join(", ")} />}
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			<script
				async
				src={`https://www.googletagmanager.com/gtag/js?id=G-4F0BC8BLV5`}
			/>
			<script
				dangerouslySetInnerHTML={{
					__html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4F0BC8BLV5', {
              page_path: window.location.pathname,
            });
          `,
				}}
			/>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `{"@context":"http://schema.org","@type":"WebSite","name":"krutikparikh.tk","alternateName":"Krutik Parikh","url":"https://krutikparikh.ca/","description":"Software Engineer with a focus on Node.js, React.js and TypeScript. I have more than 5 years experience working in software engineering.","image":"${image}"}`,
				}}
			/>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `{
			"@context": "http://schema.org",
			"@type": "Person",
			"image": "",
			"name": "Krutik Parikh",
			"alternateName": "parikrut",
			"url": "https://krutikparikh.ca/",
			"jobTitle": [
			  "Senior Software Engineer",
			  "Frontend Engineer",
			  "Front-End Developer",
			  "Web Developer",
			  "Backend Engineer",
			  "Back-End Developer",
			  "Fullstack Engineer",
			  "Full-Stack Developer"
			],
			"hasOccupation": {
			  "@type": "Occupation",
			  "name": "Senior Software Engineer",
			  "occupationLocation": { "@type": "City", "name": "Brampton" },
			  "estimatedSalary": [
				{
				  "@type": "MonetaryAmountDistribution",
				  "name": "base",
				  "currency": "CAD",
				  "duration": "P1H",
				  "percentile10": "74",
				  "percentile25": "80",
				  "median": "90",
				  "percentile75": "100",
				  "percentile90": "106"
				}
			  ],
			  "description": "Develops web applications and websites using JavaScript, React and Nodejs.",
			  "skills": "HTML5, CSS, JavaScript, React, PHP, Sass, Less, Node.js, Express.js, Vue.js, Gatsby, Next.js, JavaScript Frameworks, Git, Github, NPM, SEO, CMS, WordPress, Joomla, Drupal, E-Commerce",
			  "alternateName": [
				"Frontend Developer",
				"Full Stack Developer",
				"Frontend Engineer"
			  ],
			  "responsibilities": [
				"App Development",
				"Web Design",
				"Website Development",
				"JavaScript Development",
				"WordPress Development",
				"CMS Development",
				"Frontend Development",
				"Full Stack Development",
				"Online Marketing",
				"SEO Services",
				"Web Developer",
				"Website Maintenance"
			  ]
			},
			"sameAs": [
			  "https://www.linkedin.com/in/parikrut/",
			  "https://github.com/parikrut",
			  "https://www.youtube.com/channel/UCe0Aoc-4Eo_ywNYsPo5NEKg?view_as=subscriber",
			  "https://www.instagram.com/ikrutikparikh/"
			],
			"brand": [
			  {
				"@type": "Brand",
				"name": "Software Engineer",
				"alternateName": "Front-End Engineer"
			  },
			  { "@type": "Brand", "name": "parikut", "alternateName": "Krutik Parikh" }
			],
			"memberOf": [
				{
				  "@type": "Organization",
				  "url": "https://www.hp.com/ca-en/home.html",
				  "name": "Hp Inc",
				  "alternateName": "Hewlett-Packard"
				}
			  ],
			"homeLocation": { "@type": "City", "name": "Brampton" },
			"owns": {
			  "@type": "Organization",
			  "url": "https://www.neighbur.com/",
			  "name": "Neighbur",
			  "alternateName": "Neighbur",
			  "sameAs": "https://www.linkedin.com/company/snap-newspaper-group-inc-/"
			},
			"alumniOf": {
			  "@type": "Organization",
			  "url": "https://www.brandfire.ca/",
			  "name": "Brandfire"
			},
			"nationality": { "@type": "Country", "name": "India" },
			"birthDate": "1998-12-04",
			"birthPlace": {
			  "@type": "Place",
			  "address": {
				"@type": "PostalAddress",
				"addressLocality": "Ahmedabad",
				"addressCountry": "India"
			  }
			}
		  }
		  `,
				}}
			/>
		</Head>
	);
};

export { SeoHead };
